import * as React from "react"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ data, className, children }) => {
  if (!data) return false
  if (!data.uid && !data.url) return false

  if (data.link_type === "Document") {
    return (
      <GatsbyLink className={className} to={`/${data.uid}`}>
        {children}
      </GatsbyLink>
    )
  }

  if (data.link_type === "Web") {
    return (
      <a
        className={className}
        href={data.url}
        rel="noreferrer"
        target={data.target}
      >
        {children}
      </a>
    )
  }

  if (data.link_type === "Media") {
    return (
      <a className={className} href={data.url} rel="noreferrer" target="_blank">
        {children}
      </a>
    )
  }
}

export default Link
