import React from "react"
import { Link as ScrollLink } from "react-scroll"
import { Link } from "gatsby"

const Item = ({ item, isHomePage }) => {
  if (isHomePage) {
    return (
      <ScrollLink
        className="nav-link"
        activeClass="nav-link--active"
        to={item.to}
        href={`#${item.to}`}
        spy={true}
        smooth={true}
        duration={500}
        offset={-110}
        hashSpy={true}
      >
        {item.title}
      </ScrollLink>
    )
  } else {
    return (
      <Link
        className="nav-link"
        to={`${item.hasPage ? `/${item.to}` : `/#${item.to}`}`}
        activeClassName="nav-link--active"
        partiallyActive={true}
      >
        {item.title}
      </Link>
    )
  }
}

export default Item
