import React, { useState, useEffect } from "react"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import HeaderLogo from "./headerLogo"
import HeaderLink from "./headerLink"

const Header = ({ siteTitle, isHomePage = false }) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicResource(limit: 1) {
        nodes {
          uid
        }
      }
    }
  `)

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  const items = [
    {
      title: "Refund Control™",
      to: "refund-control",
      hasPage: false,
      isAvailable: true,
    },
    {
      title: "Lenders",
      to: "lenders",
      hasPage: false,
      isAvailable: true,
    },
    {
      title: "News",
      to: "news",
      hasPage: true,
      isAvailable: true,
    },
    {
      title: "Resources",
      to: "resources",
      hasPage: true,
      isAvailable: data.allPrismicResource.nodes.length > 0,
    },
    {
      title: "Team",
      to: "team",
      hasPage: false,
      isAvailable: true,
    },
  ]

  const [headerHeight, setHeaderHeight] = useState("var(--header-height)")
  const [logoWidth, setLogoWidth] = useState("var(--logo-width)")

  const scrollTop = () => {
    return (
      (document.scrollingElement && document.scrollingElement.scrollTop) ||
      document.body.scrollTop ||
      window.pageYOffset
    )
  }

  const eventScroll = () => {
    const st = scrollTop()
    if (st > 0) {
      if (headerHeight === "var(--header-height)") {
        setHeaderHeight("var(--header-height-reduced)")
        setLogoWidth("var(--logo-width-reduced")
      }
    } else {
      if (headerHeight === "var(--header-height-reduced)") {
        setHeaderHeight("var(--header-height)")
        setLogoWidth("var(--logo-width")
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", eventScroll, false)
    return () => {
      window.removeEventListener("scroll", eventScroll, false)
    }
  })

  return (
    <header className="fixed top-0 inset-x-0 z-20">
      <div
        style={{ height: headerHeight }}
        className="lct-header flex items-center px-ogs bg-white shadow-header"
      >
        <div className="w-full flex items-center justify-between lg:grid lg:grid-cols-12 gap-gs">
          <div className="lg:col-span-3 flex items-center">
            {isHomePage ? (
              <button
                style={{ width: logoWidth }}
                className="lct-logo block focus:outline-none"
                onClick={scrollToTop}
              >
                <HeaderLogo />
              </button>
            ) : (
              <Link
                to="/"
                style={{ width: logoWidth }}
                className="lct-logo block"
              >
                <HeaderLogo />
              </Link>
            )}
          </div>
          <div className="hidden md:flex flex-1 lg:col-span-6 h-full items-center justify-between">
            {items
              .filter(item => item.isAvailable)
              .map((item, index) => {
                return (
                  <HeaderLink key={index} item={item} isHomePage={isHomePage} />
                )
              })}
          </div>
          <div className="lg:col-start-10 lg:col-span-3 h-full flex items-center justify-end">
            {isHomePage ? (
              <ScrollLink
                className="btn-primary"
                to="demo"
                spy={true}
                smooth={true}
                duration={500}
                offset={-110}
                hashSpy={true}
              >
                Demo
              </ScrollLink>
            ) : (
              <Link to="/#demo" className="btn-primary">
                Demo
              </Link>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
