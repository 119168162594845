import * as React from "react"
import { animateScroll as scroll } from "react-scroll"
import { StaticQuery, graphql } from "gatsby"
import { Link as GatsbyLink } from "gatsby"
import Link from "./link"
import Fade from "react-reveal/Fade"

const IconA = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 50 50">
      <polygon points="10.66 14.34 12.84 16.52 23.46 5.91 23.46 50 26.54 50 26.54 5.91 37.16 16.52 39.34 14.34 25 0 10.66 14.34" />
    </svg>
  )
}

const IconB = () => {
  return (
    <svg className="w-full h-full fill-current" viewBox="0 0 50 50">
      <path d="M11.1,15.37H.33v34H11.1ZM5.79.66C2.25.66,0,3,0,6a5.3,5.3,0,0,0,5.63,5.33C9.32,11.31,11.41,9,11.41,6A5.3,5.3,0,0,0,5.79.66ZM37.62,14.59a12.82,12.82,0,0,0-10.77,5.48h-.16l-.48-4.7H16.73c.16,3.14.32,6.58.32,10.8V49.34H27.82V29.77a7.19,7.19,0,0,1,.32-2.66,6,6,0,0,1,5.62-3.91c3.86,0,5.47,3,5.47,7.36V49.34H50v-20C50,19.29,44.7,14.59,37.62,14.59Z" />
    </svg>
  )
}

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        prismicGlobal {
          data {
            site_name
            email_address
            linkedin_url
            phone_number
            footer_heading
            footer_sub_heading
            footer_menu {
              footer_menu_name
              footer_menu_link {
                link_type
                uid
                url
                target
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { data: globals } = data.prismicGlobal

      const year = new Date().getFullYear()

      const scrollToTop = () => {
        scroll.scrollToTop()
      }

      return (
        <div className="bg-blue">
          <Fade ssrReveal delay={225} duration={500}>
            <div className="text-white py-10 md:py-14 space-y-10 md:space-y-14 px-ogs bg-blue">
              <div className="space-y-3 md:space-y-4 text-center md:text-left">
                {globals.footer_heading && (
                  <h3 className="heading-alpha">{globals.footer_heading}</h3>
                )}
                {globals.footer_sub_heading && (
                  <h4 className="heading-delta">
                    {globals.footer_sub_heading}
                  </h4>
                )}
                {globals.phone_number && (
                  <a
                    className="link inline-block heading-beta"
                    href="tel:8179536642"
                  >
                    {globals.phone_number}
                  </a>
                )}
              </div>
              {globals.email_address && (
                <div className="flex flex-col items-center md:items-start space-y-4">
                  <a
                    className="btn-primary btn-primary--lg btn-primary--alt"
                    href="mailto:letstalk@lct1.com"
                  >
                    {globals.email_address}
                  </a>
                  <GatsbyLink
                    className="btn-primary btn-primary--lg btn-primary--on-blue"
                    to="/sign-up"
                  >
                    Sign up
                  </GatsbyLink>
                </div>
              )}
              <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0 md:space-x-gs">
                {globals.linkedin_url && (
                  <a
                    className="btn-circle"
                    href={globals.linkedin_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="w-5 h-6">
                      <IconB />
                    </div>
                  </a>
                )}
                {globals.footer_menu.length > 0 && (
                  <div className="flex-1 space-y-2.5">
                    <div className="flex flex-col md:flex-row flex-wrap items-center gap-y-2 md:gap-y-0 md:gap-x-gs">
                      {globals.footer_menu.map((item, index) => {
                        return (
                          <Link
                            data={item.footer_menu_link}
                            key={index}
                            className="link-basic text-md inline-flex font-semibold leading-snug"
                          >
                            {item.footer_menu_name}
                          </Link>
                        )
                      })}
                    </div>
                    <p className="text-xs leading-tight text-center md:text-left">
                      {globals.site_name} &copy; {year}. All Rights Reserved.
                    </p>
                  </div>
                )}
                <button
                  className="btn-circle flex-shrink-0"
                  onClick={scrollToTop}
                >
                  <div className="w-8 h-8">
                    <IconA />
                  </div>
                </button>
              </div>
            </div>
          </Fade>
        </div>
      )
    }}
  />
)

export default Footer
