import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/700.css"

import Header from "./header/header"
import Footer from "./footer"

const Layout = ({ children, isHomePage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        isHomePage={isHomePage}
      />
      <div className="layout relative z-10">
        <main className="layout__main">{children}</main>
        <footer className="layout__footer relative z-20">
          <Footer />
        </footer>
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
